.formContentBox[data-v-0e9f25b0] {
  height: 100%;
}
.formMain[data-v-0e9f25b0] {
  width: 100%;
  height: 100%;
}
.shuttleBackBox[data-v-0e9f25b0] {
  width: 100%;
  height: calc(100% - 225px);
}
.shuttleBackBox .el-tabs[data-v-0e9f25b0] {
  width: 100%;
}
.shuttleBackBox .upload-demo[data-v-0e9f25b0] {
  background-color: #ffffff;
}
.shuttleBackBox .logFiles[data-v-0e9f25b0] {
  width: 100%;
  height: 400px;
}